<!-- 余额流水 -->
<template>
    <!-- 内容 -->
    <div class="financebox">

        <el-card>
            <div class="global-search-box">
                <div class="search-content">
                    <div class="search-item">
                        <label class="search-label">用户信息：</label>
                        <el-input type="text" v-model="search.userInfo.value" placeholder="用户信息" />
                    </div>
                    <div class="search-item">
                        <label class="search-label">时间：</label>
                        <div class="search-time-between">
                            <el-date-picker v-model="search.created_at" type="datetimerange"
                                :picker-options="pickerOptionLater" start-placeholder="开始日期" format="yyyy-MM-dd"
                                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="search-item">
                        <label class="search-label">会员等级：</label>
                        <el-select v-model="search.level" clearable placeholder="请选择">
                            <el-option v-for="(item, index) in common_info['user.level_list']" :key="item.id"
                                :label="item.name" :value="item.level">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">类型：</label>
                        <el-select v-model="search.type" clearable placeholder="请选择">
                            <el-option v-for="(item, index) in userAccountLogType" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">操作人：</label>
                        <el-select v-model="search.admin_user_id" clearable placeholder="请选择">
                            <el-option v-for="item in permissonUserList.data" :key="item.id" :label="item.user_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="serach-buttom-button">
                    <el-button type="primary" @click="handleSearch">搜索</el-button>
                    <el-button @click="exportTable">导出报表</el-button>
                    <el-button @click="handleClear">重置条件</el-button>
                </div>

            </div>
        </el-card>
        <el-card>
            <div class="financebut">
                <div class="batchtable">
                    <div class="batchtablecon">
                        <el-table :data="tableData">
                            <el-table-column label="用户信息" width="180">
                                <template slot-scope="scope">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img :src="scope.row.users && scope.row.users.avatar_url" alt="avatar">
                                        </div>
                                        <div class="memberuseright">
                                            <p>{{ scope.row.users && scope.row.users.nickname }}</p>
                                            <p>ID: {{ scope.row.users && scope.row.users.id }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="手机号" prop="users.binding" width="180"></el-table-column>
                            <el-table-column label="类型" width="180">
                                <template slot-scope="scope">
                                    <span>{{
                                        common_info['user.account_log_type'] &&
                                        common_info['user.account_log_type'][scope.row.type]
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作人" width="200">
                                <template slot-scope="scope">
                                    <!-- 根据order_type == 7来判断操作人。7-后台支付，操作人为admin_user中的信息，其他-微信支付则为自己的username -->
                                    <span>{{ scope.row.order_type == 7 ? scope.row.admin_user &&
                                        scope.row.admin_user.user_name
                                        || (scope.row.users && scope.row.users.nickname) :
                                        scope.row.users && scope.row.users.nickname }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="时间" width="140">
                                <template slot-scope="scope">
                                    <div class="tableaddress">
                                        <span>{{ scope.row.created_at }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="变动金额" prop="invoicesend" width="140">
                                <template slot-scope="scope">
                                    <div class="tableaddress">
                                        <div class="financezt">
                                            <p>总: {{ Number.parseInt(scope.row.money).toFixed(2) || "0.00" }}</p>
                                            <!-- type：1-收入 2-支出 -->
                                            <span
                                                :class="{ 'finsuccess': scope.row.type == 1 ? true : false, 'finytk': scope.row.type == 2 ? true : false }">{{
                                                    scope.row.type
                                                    == 1 ? '+' : '-' }}{{ (scope.row.price) }}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="200" style="text-align: center;" align="center">
                                <template slot-scope="scope">
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="handleSeeDesc(scope.row)">查看备注</a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- <table>
                        <thead>
                            <tr>
                                <th class="tableleft">用户信息</th>
                                <th>手机号</th>
                                <th>类型</th>
                                <th>操作人</th>
                                <th>时间</th>
                                <th>变动金额</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <colgroup>
                            <col style="width:18%">
                            <col style="width:13%">
                            <col style="width:12%">
                            <col style="width:12%">
                            <col style="width:17%">
                            <col style="width:14%">
                            <col style="width:14%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 176</p>
                                        </div>
                                    </div>
                                </td>
                                <td>15232885599</td>
                                <td>后台充值</td>
                                <td>超级管理员</td>
                                <td>2023.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <p>总: 123456.00</p>
                                        <span class="finsuccess">+ 123456.00</span>
                                        <span class="finytk" style="display: none;">- 12333.00</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="operatortype = true">查看备注</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 6</p>
                                        </div>
                                    </div>
                                </td>
                                <td>15232885529</td>
                                <td>后台充值</td>
                                <td>张三</td>
                                <td>2022.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <p>总: 1456.00</p>
                                        <span class="finsuccess" style="display: none;">+ 123456.00</span>
                                        <span class="finytk">- 12333.00</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">查看订单</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 16</p>
                                        </div>
                                    </div>
                                </td>
                                <td>15232884599</td>
                                <td>后台充值</td>
                                <td>李四</td>
                                <td>2021.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <p>总: 3456.00</p>
                                        <span class="finsuccess" style="display: none;">+ 123456.00</span>
                                        <span class="finytk">- 12333.00</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">查看订单</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                                                                        </table> -->
                    </div>
                    <div class="tablepage clearfloat">
                        <div class="orderlastbutright">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="pageInfo.page" :page-sizes="[10, 20, 30, 50]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <!-- 查看备注弹层 -->
        <el-dialog title="备注" class="operatortc" :visible.sync="operatortype">
            <div class="operatorbox">
                <p>备注：<span>{{ this.handleSeeDesc.desc || '无' }}</span></p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="operatortype = false">取 消</el-button>
                <el-button type="primary" @click="operatortype = false">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 导出报表 -->
        <diy-export-more ref="diyExportMore" dialogExportKeys="balance.record.field.list"
            dialogExportUrl="/finance/user_account_log_export" :dialogExportIsSearch="true"></diy-export-more>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '../../../common/common'
import { Message } from 'element-ui';

export default {
    data() {
        return {
            search: {
                userInfo: {
                    option: "user_info", //选择下来key值
                    value: "",//输入的值
                    type: 'selectSearch',
                    op: "search"
                },
                level: "",//等级
                created_at: [],//创建时间
                type: "",//类型
                admin_user_id: "",//操作人
            },
            searchObj: {
                search: [],
                order: [
                    {
                        "key": "id",
                        "value": "desc"
                    }
                ]
            },
            pageInfo: {
                page: 1,
                limit: 10
            },
            tableData: [],//流水列表数据
            total: 0,//条数
            operatortype: false,//备注dialog
            pickerOptionLater: {
                // 所有的截止时间为当天的23.59.59
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]

            },
        }
    },
    beforeCreate() {
        // 清空commonInfo缓存
        this.$store.commit("commonInfo", "");
    },
    created() {
        //请求全局配置资源(user.level_list,user.is_freeze)
        common.getCommonInfo(this, { keys: Array.of("user.account_log_type", "user.level_list") });
        // console.log(this.permissonUserList());
        this.getUserAccountList();
    },
    methods: {
        // 提取搜索数据
        extractSearch() {
            return { ...this.searchObj, ...this.pageInfo }
        },
        // 打开导出报表弹窗
        exportTable() {
            this.$refs.diyExportMore.exportMore = true
        },
        // 搜索
        handleSearch() {
            this.$set(this, 'searchObj', { ...this.searchObj, ...this.pageInfo });
            this.$set(this.searchObj, 'search', common.handleSearch(this.search));
            this.getUserAccountList();
        },
        // 清空
        handleClear() {
            this.$set(this, 'search', common.handleClear(this.search));
            this.handleSearch();
        },
        // 获取所有管理员列表
        async permissonUserList() {
            const { data } = await this.$get(this.$apis.permissonUserList);
            this.permissonUserList.data = data;
        },
        // 流水列表数据
        getUserAccountList() {
            this.$get(this.$apis.finaceUserAccountApi, { ...this.searchObj, ...this.pageInfo }).then(res => {
                if (res.code != 200) {
                    Message.error({
                        message: res.message,
                        offset: 200
                    })
                } else {
                    this.$set(this, "tableData", res.data.list);
                    this.total = res.data.total;
                }
            })
        },
        // pageSize
        handleSizeChange(limit) {
            this.$set(this, "pageInfo", { ...this.pageInfo, limit });
            this.getUserAccountList();
        },
        // pageNum
        handleCurrentChange(page) {
            this.$set(this, "pageInfo", { ...this.pageInfo, page });
            this.getUserAccountList();
        },
        // 查看备注
        handleSeeDesc(row) {
            this.operatortype = true;
            this.handleSeeDesc.desc = row.desc;
        }
    },
    computed: {
        // user.account_log_type
        userAccountLogType() {
            return common.mapSource2List(this.common_info['user.account_log_type'] || {});
        },
        common_info: {
            get() {
                return this.$store.state.common_info
            },
            set() { }
        }
    },
}
</script>

<style scoped lang="less">
@import url("css/finance.css");

.batchtable {
    padding: 0;
}

/deep/.el-table {
    width: 100% !important;

    & .el-table__cell {
        padding: 15px 10px;
    }

    & .el-table__header {
        width: 100% !important;
    }

    & .el-table__body {
        width: 100% !important;
    }
}
</style>